import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import CardanoDate from "./views/CardanoDate/CardanoDate";

ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path="/" component={CardanoDate}/>
        </Switch>
    </Router>,
    document.getElementById("root")
);
