import React from "react";

import {Col, Row} from "reactstrap";
import CustomEvent from "./CustomEvent";
import {extendMoment} from "moment-range";
import Moment from "moment";
import "assets/css/dayStyle.css";
import {faFileSignature} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const moment = extendMoment(Moment);

class Day extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: props.date,
            day: props.day,
            dayName: props.dayName,
            epochs: props.epochs,
            backgroundColor: props.color,
            simulate: props.simulate,
            rewards: props.rewards,
            bcolor: props.bcolor,
            delegation: props.delegation,
            metadata: props.metadata,
            bgEven: 'red',
            bgOdd: 'yellow',
            shadowCSS: ''
        };

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            date: nextProps.date,
            day: nextProps.day,
            dayName: nextProps.dayName,
            epochs: nextProps.epochs,
            backgroundColor: nextProps.color,
            simulate: nextProps.simulate,
            rewards: nextProps.rewards,
            bcolor: nextProps.bcolor,
            delegation: nextProps.delegation,
            metadata: nextProps.metadata,
        });
    }

    showBorder(show) {
        let border;
        if (show) {
            border = 'shadowInner';
        } else {
            border = '';
        }
        this.setState({
            shadowCSS: border
        }, () => {
        });
    }

    render() {

        let className;

        className = this.state.bcolor + ' ' + this.state.shadowCSS;

        let rewardsEvent;

        // Rewards
        let rewards = 0;

        if (this.props.rewards && this.props.rewards[0]) {
            rewardsEvent = 'rewards';
            rewards = this.props.rewards[0].amount;
        } else {
            rewardsEvent = 'default';
        }

        let delegationEvent;
        if (this.props.delegation !== undefined && this.props.delegation.time) {
            delegationEvent = 'delegation';
        } else {
            delegationEvent = 'default';
        }


        return (
            <>

                <td
                    id={this.state.date}
                    className={className}
                    onMouseEnter={() => this.showBorder(true)}
                    onMouseLeave={() => this.showBorder(false)}
                    onClick={this.props.onClick}
                >
                    <Row className="dayRow">

                        <Col sm="7" md="8" className="epochNumCol">
                            <div className="epochNum">
                                {this.state.epochs[0]}
                                {this.state.epochs && this.state.epochs[1] ? (
                                    <div className="epochNum initEpochNumDay">
                                        <div className="epochNum newEpochNumIndicator">{this.state.epochs[1]}</div>
                                    </div>
                                ) : (
                                    null
                                )}
                            </div>


                        </Col>
                        <Col sm="5" md="4" className="dayNumberCol">
                            <p className="dayNumber">{this.props.day}</p>
                        </Col>
                    </Row>
                    <div className="eventsDay">


                        {rewards ? (
                            <div className="eventDay rewardsEventType"><CustomEvent type={rewardsEvent}
                                                                                    rewards={rewards}
                                                                                    icon={<img id=""
                                                                                               className="adabag-icon"
                                                                                               src={require("assets/img/adaBag-iconmin2.png")}
                                                                                               alt=""/>}/></div>
                        ) : (
                            null
                        )}

                        {this.props.delegation && this.props.delegation.time ? (
                            <div className="eventDay"><CustomEvent type={delegationEvent}
                                                                   icon={<FontAwesomeIcon icon={faFileSignature}/>}/>
                            </div>
                        ) : (
                            null
                        )}

                        {this.props.metadata && this.props.metadata.length > 0 ? (
                            <div className="eventDay">
                                <img id=""
                                     className="metadata-icon"
                                     src={require("assets/img/metadata-min32.png")}
                                     alt=""/>
                                <span className="numMetasDay">({this.props.metadata.length})</span>
                            </div>
                        ) : (
                            null
                        )}
                    </div>

                </td>
            </>
        );
    }
}

export default Day;

