import React from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroupAddon,
    InputGroupText,
    Row,
    UncontrolledCollapse
} from "reactstrap";

import {InputGroup, Nav, Navbar, OverlayTrigger, Tab, Tabs, Tooltip} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/css/calendarPro.css";
import "assets/css/colors.css";

import "assets/css/sandClock.scss";
import "assets/css/switchTheme.css";
import "assets/css/calendarStyle.css";
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import Day from "./Day";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleUp, faCopy, faHeart} from '@fortawesome/free-solid-svg-icons'
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {faWindowClose} from "@fortawesome/free-regular-svg-icons";
import BirdsFlying from "../Animated/BirdsFlying";

// logo nav
import logo from '../../assets/img/logo.png'
import Toast from "../../components/Toast";
import {addressSlice, copyText} from '../../utils/utils'
import {ADDRESS} from "../../constants/account";
import {getRewardsByStakeAddress} from "../../api/api";
import {POSTGRES_MAINNET} from "../../constants/endpoints";

const moment = extendMoment(Moment);

class CardanoDate extends React.Component {
    myRef;


    constructor(props) {
        super(props);

        this.state = {
            eras: {
                'Bryon': {epoch_no: 0},
                'Shelley': {epoch_no: 208},
                'Allegra': {epoch_no: 236},
                'Mary': {epoch_no: 251},
                'Alonzo': {epoch_no: 290}
            },

            // Animated
            birdsAnimated: true,
            loadAnimated: true,

            eras_epoch: [[0, 'Byron', 0], [208, 'Shelley', 0], [236, 'Allegra', 0], [251, 'Mary', 0], [290, 'Alonzo', 0]],
            showEras: false,

            days: [],
            height: 0,
            width: 0,
            curTime: 0,
            currentEpoch: 0,
            firstEpoch: 0,
            epochs: {},
            epochsParams: [],
            simulatedEpochs: {},
            stakingDate: moment(moment.utc()).utc().format('YYYY-MM-DD'),

            dayComponents: [],
            calendarWeeks: [],
            dayObjectList: [],

            totalDays: 0,
            openTopMenu: true,
            displayFooter: false,
            showLoading: false,
            alreadyReducedTbody: false,
            tableBodyHeight: 0,
            displayDay: {
                date: null,
                epochs: [],
                simulate: false,
                rewards: {}
            },
            delegYear: null,
            delegMonth: null,
            delegDay: null,
            delegHour: null,
            delegMin: null,
            delegSec: null,
            delegFinalDate: null,

            // Rewards history
            stakeAddress: 'stake1uxdtken3pesclvqpxjglu93y0c0p0k9nctxxrvz9fcsuqrgsah8z6',    // Ada Booster
            stakeAddressId: null,
            rewardsHistory: [],
            totalRewards: 0,
            totalRewardsNum: 0,

            // Stake pool
            stakePoolUrl: "",
            stakePoolInfo: [],
            stakePoolJson: "",
            stakePoolJsonDelegation: "",
            stakeHistory: {},
            poolJsonIdDict: {},

            // Metadata
            metadataKey: "",
            metadataStartDate: moment.utc().subtract(10, 'day').format('DD-MM-YY'),
            metadataEndDate: moment.utc().format('DD-MM-YY'),
            metadataObjects: [],

            //Range epochs slider
            rangeSliderValue: 0,


        };
        this.itemRefs = {};

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


        this.getRewardsFromAddress = this.getRewardsFromAddress.bind(this);
        this.initCalendar = this.initCalendar.bind(this);
        this.changeColorDays = this.changeColorDays.bind(this);


    }


    async componentDidMount() {
        document.body.classList.toggle("calendar");
        this.updateWindowDimensions();

        setInterval(() => {
            this.setState({
                curTime: moment(moment.utc()).format('HH:mm:ss D MMMM YY')
            }, () => {

            });


        }, 1000);

        this.getFirstEpoch().then(r => null);

        // with first example

        await this.getAllEpochs();



    }

    componentWillUnmount() {
        document.body.classList.toggle("calendar");
        this.updateWindowDimensions();
    }


    initCalendar() {

        // TODO delete ?
        // this.getMetadata();


        let totalDays;

        let epochsState = this.state.epochs;

        let allParameters = this.state.epochsParams;
        let showEras = this.state.showEras;

        if (epochsState.length > 0) {
            this.setState({
                currentEpoch: epochsState[epochsState.length - 1].no,
                rangeSliderValue: epochsState[epochsState.length - 1].no
            });
            // TODO: poner aqui=> }

            totalDays = moment(moment.utc(epochsState[0].start_time, 'YYYYY-MM-DDTHH:mm:ss') - moment.utc(epochsState[0].end_time, 'YYYYY-MM-DDTHH:mm:ss')).utc().day() - 1

            epochsState[epochsState.length - 1].end_time = moment.utc(epochsState[epochsState.length - 1].start_time, 'YYYYY-MM-DDTHH:mm:ss').add(totalDays, 'day').utc();
            let epochs2 = this.addSimulatedEpochs(epochsState, 74);


            let weeks = [];
            // Recorremos cada epoch y creamos sus dias, los vamos metiendo en weeks cada 7
            let startTime;

            let currentTime;
            let week = [];
            let aux = 0;

            for (let e = 0; e < epochs2.length; ++e) {
                startTime = moment.utc(epochs2[e].start_time,'YYYYY-MM-DDTHH:mm:ss').utc();
                currentTime = startTime;


                for (let d = 0; d < totalDays; d++) {
                    let epochsDay = [];
                    // Set first day
                    let epocBefore;
                    if (aux > 0 && d === 0) {
                        epocBefore = epochs2[e - 1].no;
                        epochsDay.push(epochs2[e - 1].no);
                    }
                    epochsDay.push(epochs2[e].no);


                    let currId = currentTime.format('YYYY-MM-DD');
                    let currDate = currId;
                    let dayName = currentTime.format('dddd');
                    let currDay = currentTime.format('DD');

                    // parameters
                    // add 1 or two, in depends of the num of epoch in the day
                    let parameters = [];

                    if (allParameters[epochsDay[0]]) {
                        parameters.push(allParameters[epochsDay[0]]);
                    }
                    if (allParameters[epochsDay[1]]) {
                        parameters.push(allParameters[epochsDay[1]]);
                    }

                    let bcolor = this.getColorDay(epochsDay, currDate, showEras);

                    week = [...week, <Day
                        id={currId}
                        date={currDate}
                        day={currDay}
                        dayName={dayName}
                        week={aux}
                        epochs={epochsDay}
                        parameters={parameters}
                        bcolor={bcolor}
                        simulate={moment(currentTime.format('YYYY-MM-DD')).isAfter(moment(this.state.epochs[this.state.epochs.length - 1].end_time))}
                        rewards={[]}
                        delegation={[]}
                        metadata={[]}
                        ref={(r) => (this.itemRefs[currentTime.format('YYYY-MM-DD')] = r)}
                        onClick={() => {
                            this.showDayDetails(currId,
                                currDate,
                                dayName,
                                currDay,
                                [epocBefore].concat([epochs2[e].no]),
                                [],
                                [],
                                parameters,
                                bcolor,
                                [])
                        }
                        }
                    />
                    ]
                    //[epocBefore].concat([epochs2[e].no])
                    if (currentTime.format('ddd') === 'Sat') {

                        weeks[aux] = week;
                        week = [];
                        aux++;

                    } else {

                    }

                    currentTime = currentTime.add(1, 'day');
                    epochsDay = [];
                }

            }

            // Fix first week
            let daysToCreate = 7 - weeks[0].length;

            for (let d = 0; d < daysToCreate; d++) {
                weeks[0].unshift(<Day
                    id={'x'}
                    date={'x'}
                    day={'x'}
                    dayName={'xxx'}
                    week={0}
                    epochs={[]}
                    bcolor="backgroundDefaultWhite"
                    simulate={true}
                />)
            }

            this.setState({
                dayObjectList: weeks
            }, () => {


                this.scrollTo(moment().subtract(30, "days").format('YYYY-MM-DD'));
                // Initial example
                this.setState({
                    loadAnimated: false
                });

            });
        }
    }

    pillsScroll(eraName) {

        if (eraName === 'Mary') {
            this.setState({
                birdsAnimated: !this.state.birdsAnimated
            }, () => {

            });
        }

        if (this.state.epochs && this.state.epochs.length && this.state.eras[eraName].epoch_no) {
            let date = moment.utc(this.state.epochs[this.state.eras[eraName].epoch_no].start_time, 'YYYYY-MM-DDTHH:mm:ss').add(1, 'day').format('YYYY-MM-DD');
            this.scrollTo(date);
        }
    }

    calcRangeSliderColors() {

        let eras = this.state.eras_epoch;

        let lastEpoch = this.state.epochs.length;

        let value;
        for (let i = 0; i < eras.length; i++) {
            eras[3] = (eras[i] / lastEpoch) * 100;
        }

        let startColor = 'linear-gradient(90deg, red 33.33%, white 33.33%, white 66.66%, blue 66.66%)';

        this.setState({
            eras_epoch: eras
        }, () => {

        });
    }

    getEraFromEpoch(epoch) {

        // 22-01-2021 8:00:15
        // 0,208,230,248
        let eras_epoch = this.state.eras_epoch;

        //console.log("epoch: "+epoch);
        let era = '';

        // First era
        if (epoch >= eras_epoch[0][0] && epoch < eras_epoch[1][0]) {
            //console.log("menor que? "+eras_epoch[0][0]);
            //console.log("epoch "+epoch+" pertenece a "+eras_epoch[0][1]);
            era = eras_epoch[0][1]
        }
        // Last era
        else if (epoch >= eras_epoch[eras_epoch.length - 1][0]) {
            //console.log("mayor que? "+eras_epoch[eras_epoch.length-1][0]);
            //console.log("epoch "+epoch+" pertenece a "+eras_epoch[eras_epoch.length-1][1]);
            era = eras_epoch[eras_epoch.length - 1][1]
        } else {
            for (let i = 1; i < eras_epoch.length - 1; i++) {
                //console.log("Entre "+eras_epoch[i][0] +" y "+eras_epoch[i+1][0]);
                //console.log("epoch "+epoch+" pertenece a "+eras_epoch[i][1]);
                if (epoch >= eras_epoch[i][0] && epoch < eras_epoch[i + 1][0]) {
                    era = eras_epoch[i][1];
                    break;
                }
            }
        }

        return era;
    }

    getColorDay(epochs, date, showEras) {
        let className = '';
        let erasDay = [];
        for (let i = 0; i < epochs.length; i++) {
            erasDay.push(this.getEraFromEpoch(epochs[i]));
        }
        if (epochs.length && date === "2017-09-23") {
            if (showEras) {
                className = 'backgroundDoubleInit' + erasDay[0];
            } else {
                className = 'backgroundDoubleInit';
            }
        } else if (epochs && epochs.length === 1 && epochs[0] % 2 === 0) {
            if (showEras) {
                className = 'backgroundEven' + erasDay[0];
            } else {
                className = 'backgroundEven';
            }
        } else if (epochs && epochs.length === 1 && epochs[0] % 2 !== 0) {
            if (showEras) {
                className = 'backgroundOdd' + erasDay[0];
            } else {
                className = 'backgroundOdd';
            }
        } else if (epochs && epochs.length > 1) {
            if (epochs[0] % 2 !== 0) {
                if (showEras) {
                    className = 'backgroundDoubleInverse' + erasDay[0] + erasDay[1];
                } else {
                    className = 'backgroundDoubleInverse';
                }
            } else {
                if (showEras) {
                    className = 'backgroundDouble' + erasDay[0] + erasDay[1];
                } else {
                    className = 'backgroundDouble';
                }
            }
        } else {
            if (showEras && erasDay[0]) {
                className = 'backgroundDefaultWhite' + erasDay[0];
            } else {
                className = 'backgroundDefaultWhite';
            }
        }

        if (moment.utc(date,'YYYYY-MM-DDTHH:mm:ss').isAfter(moment.utc())) {

            className = className + ' ' + 'simulatedDay';
        }

        className = className + ' ';

        return className;
    }

    handleErasCheckBox(checked) {

        this.setState({
            showEras: checked
        }, () => {
            this.changeColorDays();
        });

    }

    changeColorDays() {
        let weeks = this.state.dayObjectList;
        let showEras = this.state.showEras;

        for (let week = 0; week < weeks.length; week++) {
            for (let day = 0; day < weeks[week].length; day++) {
                let dayObject = [];
                dayObject = weeks[week][day];
                let updatedColor = this.getColorDay(dayObject.props.epochs, dayObject.props.date, showEras);

                let updatedDay = <Day
                    id={dayObject.props.id}
                    date={dayObject.props.date}
                    day={dayObject.props.day}
                    dayName={dayObject.props.dayName}
                    week={dayObject.props.week}
                    epochs={dayObject.props.epochs}
                    parameters={dayObject.props.parameters}
                    bcolor={updatedColor}
                    simulate={dayObject.props.simulate}
                    rewards={dayObject.props.rewards}
                    ref={(r) => (this.itemRefs[dayObject.props.date] = r)}
                    delegation={dayObject.props.delegation}
                    metadata={dayObject.props.metadata}
                    onClick={() => {
                        this.showDayDetails(dayObject.props.id,
                            dayObject.props.date,
                            dayObject.props.dayName,
                            dayObject.props.day,
                            dayObject.props.epochs,
                            dayObject.props.rewards,
                            dayObject.props.delegation,
                            dayObject.props.parameters,
                            updatedColor,
                            dayObject.props.metadata)
                    }}
                />
                weeks[week][day] = updatedDay;
            }
        }

        this.setState({
            dayObjectList: weeks
        }, () => {
        });
    }

    async showDayDetails(id, date, name, day, epochs, rewards, delegation, parameters, color, metadata) {

        // delete possible null epochs
        let filteredEpochs = epochs.filter(function (e) {
            return e != null;
        });

        let r;
        if (rewards && rewards[0]) {
            r = rewards[0];
            this.getPoolInfo(r['pool_id']);
        } else {
            r = [];
        }

        // get info
        let dayCoinInfo = await this.getCardanoCoinInfo(date);

        if (delegation && delegation.pool_hash_id >= 0 && delegation.pool_hash_id !== r['pool_id']) {
            this.getPoolInfo(delegation.pool_hash_id);
        }

        if (!this.state.displayFooter) {
            this.setState({
                displayFooter: true,
                displayDay: {
                    date: date,
                    dayName: name,
                    day: day,
                    epochs: filteredEpochs,
                    rewards: r,
                    delegation: delegation,
                    parameters: parameters,
                    bcolor: color,
                    dayCoinInfo: dayCoinInfo,
                    metadata: metadata
                }
            }, () => {

                this.scrollTo('toolsToggler-btn-footer');

                //this.setCalendarHeight(true);
            });
        } else if (this.state.displayFooter && this.state.displayDay.date !== day) {

            this.setState({
                displayDay: {
                    date: date,
                    dayName: name,
                    day: day,
                    epochs: filteredEpochs,
                    rewards: r,
                    delegation: delegation,
                    parameters: parameters,
                    bcolor: color,
                    dayCoinInfo: dayCoinInfo,
                    metadata: metadata
                }
            }, () => {
            });

        }
    }

    async getEpochsParams() {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        fetch('https://postgrest-api.mainnet.dandelion.link/epoch_param', requestOptions)
            .then(response => response.text())
            .then(data => {
                let epochparams = {};
                for (let i = 0; i < JSON.parse(data).length; i++) {
                    epochparams[JSON.parse(data)[i].epoch_no] = JSON.parse(data)[i];
                }
                this.setState({epochsParams: epochparams});
            }).then(t => {
            this.initCalendar();
        }).then(r => null);
    }

    async getCardanoCoinInfo(date) {   // 05-01-2021
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        const coinInfo = await fetch('https://api.coingecko.com/api/v3/coins/cardano/history?date=' + moment.utc(date).format("DD-MM-YYYY"), requestOptions);

        const coinInfoJson = await coinInfo.json();

        return coinInfoJson;
    }


    addSimulatedEpochs(epochs, numEpochs) {

        // Get current epoch num
        const currentEpochNum = epochs[epochs.length - 1].no;
        // Get when ends the current epoch
        let currentEpochEnd = moment.utc(epochs[epochs.length - 1].start_time, 'YYYYY-MM-DDTHH:mm:ss').add(5, 'days');
        // Fix current epoch end date
        epochs[currentEpochNum].end_time = currentEpochEnd.utc().format();

        for (let i = 1; i < numEpochs; i++) {
            epochs[currentEpochNum + i] = {
                out_sum: 0,
                fees: 0,
                tx_count: 0,
                blk_count: 0,
                start_time: currentEpochEnd.utc().format(),
                end_time: currentEpochEnd.add(5, 'days').utc().format(),
                no: currentEpochNum + i,
                simulate: true
            }
        }

        return epochs;
    }

    setRewardsDayByDate(date, rewards) {

        let weeks = this.state.dayObjectList;

        let dayObject = [];
        for (let week = 0; week < weeks.length; week++) {
            dayObject = weeks[week].filter(day => moment.utc(day.props.date).format('YYYY-MM-DD') === moment.utc(date).format('YYYY-MM-DD'));
            if (dayObject[0]) {
                break;
            }
        }

        let rwds = [];
        if (rewards) {
            rwds.push(rewards);
        } else {
            rwds.push(dayObject[0].props.rewards);
        }

        let updatedDay = <Day
            id={dayObject[0].props.id}
            date={dayObject[0].props.date}
            day={dayObject[0].props.day}
            dayName={dayObject[0].props.dayName}
            week={dayObject[0].props.week}
            epochs={dayObject[0].props.epochs}
            parameters={dayObject[0].props.parameters}
            bcolor={dayObject[0].props.bcolor}
            simulate={dayObject[0].props.simulate}
            rewards={rwds}
            delegation={dayObject[0].props.delegation}
            metadata={dayObject[0].props.metadata}
            ref={(r) => (this.itemRefs[dayObject[0].props.date] = r)}
            onClick={() => {
                this.showDayDetails(dayObject[0].props.id,
                    dayObject[0].props.date,
                    dayObject[0].props.dayName,
                    dayObject[0].props.day,
                    dayObject[0].props.epochs,
                    rwds,
                    dayObject[0].props.delegation,
                    dayObject[0].props.parameters,
                    dayObject[0].props.bcolor,
                    dayObject[0].props.metadata)
            }}
        />

        for (let i = 0; i < weeks[dayObject[0].props.week].length; i++) {
            if (moment(weeks[dayObject[0].props.week][i].props.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                weeks[dayObject[0].props.week][i] = updatedDay;
            }
        }

        this.setState({
            dayObjectList: weeks
        }, () => {
            this.setState({
                showLoading: false
            });
        });

    }

    setDelegationDayByDate(date, delegation) {

        let weeks = this.state.dayObjectList;


        let dayObject = [];
        for (let week = 0; week < weeks.length; week++) {
            dayObject = weeks[week].filter(day => moment(day.props.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'));
            if (dayObject[0]) {
                break;
            }
        }

        let updatedDay = <Day
            id={dayObject[0].props.id}
            date={dayObject[0].props.date}
            day={dayObject[0].props.day}
            dayName={dayObject[0].props.dayName}
            week={dayObject[0].props.week}
            epochs={dayObject[0].props.epochs}
            parameters={dayObject[0].props.parameters}
            bcolor={dayObject[0].props.bcolor}
            simulate={dayObject[0].props.simulate}
            rewards={dayObject[0].props.rewards}
            ref={(r) => (this.itemRefs[dayObject[0].props.date] = r)}
            delegation={delegation}
            metadata={dayObject[0].props.metadata}
            onClick={() => {
                this.showDayDetails(dayObject[0].props.id,
                    dayObject[0].props.date,
                    dayObject[0].props.dayName,
                    dayObject[0].props.day,
                    dayObject[0].props.epochs,
                    dayObject[0].props.rewards,
                    delegation,
                    dayObject[0].props.parameters,
                    dayObject[0].props.bcolor,
                    dayObject[0].props.metadata)
            }}
        />

        for (let i = 0; i < weeks[dayObject[0].props.week].length; i++) {
            if (moment(weeks[dayObject[0].props.week][i].props.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                weeks[dayObject[0].props.week][i] = updatedDay;
                break;
            }
        }

        this.setState({
            dayObjectList: weeks
        }, () => {
            this.setState({
                showLoading: false
            });
        });
    }

    setMetadataByDate(date, metadata) {

        let weeks = this.state.dayObjectList;

        let dayObject = [];
        for (let week = 0; week < weeks.length; week++) {
            dayObject = weeks[week].filter(day => moment(day.props.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'));
            if (dayObject[0]) {
                break;
            }
        }

        let updatedDay = <Day
            id={dayObject[0].props.id}
            date={dayObject[0].props.date}
            day={dayObject[0].props.day}
            dayName={dayObject[0].props.dayName}
            week={dayObject[0].props.week}
            epochs={dayObject[0].props.epochs}
            parameters={dayObject[0].props.parameters}
            bcolor={dayObject[0].props.bcolor}
            simulate={dayObject[0].props.simulate}
            rewards={dayObject[0].props.rewards}
            delegation={dayObject[0].props.delegation}
            metadata={metadata}
            ref={(r) => (this.itemRefs[dayObject[0].props.date] = r)}
            onClick={() => {
                this.showDayDetails(dayObject[0].props.id,
                    dayObject[0].props.date,
                    dayObject[0].props.dayName,
                    dayObject[0].props.day,
                    dayObject[0].props.epochs,
                    dayObject[0].props.rewards,
                    dayObject[0].props.delegation,
                    dayObject[0].props.parameters,
                    dayObject[0].props.bcolor,
                    metadata)
            }}
        />

        for (let i = 0; i < weeks[dayObject[0].props.week].length; i++) {
            if (moment(weeks[dayObject[0].props.week][i].props.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                weeks[dayObject[0].props.week][i] = updatedDay;
            }
        }

        this.setState({
            dayObjectList: weeks
        }, () => {
            this.setState({
                showLoading: false
            });
        });

    }

    stakingDate = (e) => {

        let selectedDate = e;    // sum 1 day to be currenty

        this.setState({
            stakingDate: moment.utc(selectedDate).utc().format()
        }, () => {

        });
    }

    async getFirstEpoch() {

        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        await fetch('https://postgrest-api.mainnet.dandelion.link/epoch?no=eq.0', requestOptions)
            .then(response => response.text())
            .then(data =>
                this.setState({
                    firstEpoch: JSON.parse(data)[0]
                    //firstEpoch: JSON.parse(data)[0]
                }, () => {

                })
            );
    }

    async getEpoch(epochN) {

        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        await fetch('https://postgrest-api.mainnet.dandelion.link/epoch?no=eq.' + epochN, requestOptions)
            .then(response => response.text())
            .then(epoch =>
                this.setState({
                    currentEpoch: JSON.parse(epoch)[0]
                }, () => {
                })
            )
    }

    async getAllEpochs() {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        fetch('https://postgrest-api.mainnet.dandelion.link/epoch', requestOptions)
            .then(response => response.text())
            .then(data => {
                this.setState({epochs: this.sortByKey(JSON.parse(data), 'id')});
            }).then(e => this.getEpochsParams());
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    sortByKey(array, key) {
        if (array.length) {
            return array.sort(function (a, b) {
                let x = a[key];
                let y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        } else {
            return array;
        }

    }

    scrollTo(id) {
        const divToScrollTo = document.getElementById(id);
        if (divToScrollTo) {
            divToScrollTo.scrollIntoView({behavior: "smooth"});
        }
    }

    containsFirstDayMonth(week) {
        let month = 0;

        let result = week.filter((day) => day.props.day == 1).map(day => month = day.props.date);

        if (result.length) {
            month = moment(result, 'YYYY/MM/DD').format('MMMM YY');
        }
        return month;
    }

    turnOffDayDetails = (e) => {
        const dayDetailsHeight = document.getElementById('dayDetails').offsetHeight;

        if (this.state.displayFooter) {
            this.setState({
                displayFooter: false
            }, () => {
                //this.setCalendarHeight(false);

                this.setState({
                    height: this.state.height + dayDetailsHeight
                });
            });
        }
    }

    cleanRewardsAndDelegation() {

        let weeks = this.state.dayObjectList;
        // TODO id tiene q empezar en el epoch 208
        for (let i = 0; i < weeks.length; i++) {
            for (let d = 0; d < weeks[i].length; d++) {

                weeks[i][d] = <Day
                    id={weeks[i][d].props.id}
                    date={weeks[i][d].props.date}
                    day={weeks[i][d].props.day}
                    dayName={weeks[i][d].props.dayName}
                    week={weeks[i][d].props.week}
                    epochs={weeks[i][d].props.epochs}
                    parameters={weeks[i][d].props.parameters}
                    bcolor={weeks[i][d].props.bcolor}
                    simulate={weeks[i][d].simulate}
                    rewards={[]}
                    delegation={[]}
                    metadata={weeks[i][d].props.metadata}
                    ref={(r) => (this.itemRefs[weeks[i][d].props.date] = r)}
                    onClick={() => {
                        this.showDayDetails(weeks[i][d].props.id,
                            weeks[i][d].props.date,
                            weeks[i][d].props.dayName,
                            weeks[i][d].props.day,
                            weeks[i][d].props.epochs,
                            [],
                            [],
                            weeks[i][d].props.parameters,
                            weeks[i][d].props.bcolor,
                            weeks[i][d].props.metadata)
                    }}
                />
            }
        }

        this.setState({
            dayObjectList: weeks
        }, () => {
        });
    }

    cleanAndSetRewardsFromAddress() {

        let weeks = this.state.dayObjectList;
        // TODO id tiene q empezar en el epoch 208
        for (let i = 0; i < weeks.length; i++) {
            for (let d = 0; d < weeks[i].length; d++) {

                weeks[i][d] = <Day
                    id={weeks[i][d].props.id}
                    date={weeks[i][d].props.date}
                    day={weeks[i][d].props.day}
                    dayName={weeks[i][d].props.dayName}
                    week={weeks[i][d].props.week}
                    epochs={weeks[i][d].props.epochs}
                    parameters={weeks[i][d].props.parameters}
                    bcolor={weeks[i][d].props.bcolor}
                    simulate={weeks[i][d].simulate}
                    rewards={[]}
                    delegation={[]}
                    metadata={weeks[i][d].props.metadata}
                    ref={(r) => (this.itemRefs[weeks[i][d].props.date] = r)}
                    onClick={() => {
                        this.showDayDetails(weeks[i][d].props.id,
                            weeks[i][d].props.date,
                            weeks[i][d].props.dayName,
                            weeks[i][d].props.day,
                            weeks[i][d].props.epochs,
                            [],
                            [],
                            weeks[i][d].props.parameters,
                            weeks[i][d].props.bcolor,
                            weeks[i][d].props.metadata
                        )
                    }}
                />
            }
        }

        this.setState({
            dayObjectList: weeks
        }, () => {
            this.setRewardsFromAddress();
        });
    }

    setRewardsFromAddress() {
        let rewards = JSON.parse(this.state.rewardsHistory);
        console.log('rewards');
        console.log(rewards);
        let date;
        let totalRewards = 0;
        for (let i = 0; i < rewards.length; i++) {
            date = moment.utc(this.state.epochs[rewards[i].spendable_epoch-2].start_time,'YYYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            this.setRewardsDayByDate(date, rewards[i]);
            totalRewards = totalRewards + rewards[i].amount;
        }

        this.setState({
            totalRewards: (totalRewards / 1000000),
            totalRewardsNum: rewards.length
        }, () => {
            this.scrollTo((moment.utc(this.state.epochs[rewards[0].spendable_epoch-2].start_time,'YYYYY-MM-DDTHH:mm:ss')).subtract(30, "days").utc().format('YYYY-MM-DD'));
            this.setState({
                loadAnimated: false
            });
        });
    }

    cleanAndSetMetadata() {

        let weeks = this.state.dayObjectList;
        // TODO id tiene q empezar en el epoch 208
        for (let i = 0; i < weeks.length; i++) {
            for (let d = 0; d < weeks[i].length; d++) {

                weeks[i][d] = <Day
                    id={weeks[i][d].props.id}
                    date={weeks[i][d].props.date}
                    day={weeks[i][d].props.day}
                    dayName={weeks[i][d].props.dayName}
                    week={weeks[i][d].props.week}
                    epochs={weeks[i][d].props.epochs}
                    parameters={weeks[i][d].props.parameters}
                    bcolor={weeks[i][d].props.bcolor}
                    simulate={weeks[i][d].simulate}
                    rewards={weeks[i][d].rewards}
                    delegation={weeks[i][d].delegation}
                    metadata={[]}
                    ref={(r) => (this.itemRefs[weeks[i][d].props.date] = r)}
                    onClick={() => {
                        this.showDayDetails(weeks[i][d].props.id,
                            weeks[i][d].props.date,
                            weeks[i][d].props.dayName,
                            weeks[i][d].props.day,
                            weeks[i][d].props.epochs,
                            weeks[i][d].props.rewards,
                            weeks[i][d].props.delegation,
                            weeks[i][d].props.parameters,
                            weeks[i][d].props.bcolor,
                            []
                        )
                    }}
                />
            }
        }

        this.setState({
            dayObjectList: weeks
        }, () => {
            this.setMetadata();
        });
    }

    setMetadata() {
        let metadata = this.state.metadataObjects;

        if (metadata[0]) {
            let currentDate = moment.utc(metadata[0].block_time, 'YYYYY-MM-DDTHH:mm:ss');

            let metaMap = {};
            for (let i = 0; i < metadata.length; i++) {

                // get YYYY-MM-DD
                currentDate = metadata[i].block_time.split('T')[0];

                if (metaMap[currentDate]) {
                    metaMap[currentDate] = metaMap[currentDate].concat([metadata[i]]);
                } else {
                    metaMap[currentDate] = [metadata[i]]
                }

            }
            for (let key in metaMap) {
                this.setMetadataByDate(key, metaMap[key]);
            }
        }

    }

    setDelegationFromStakeAddress() {

        let delegation = this.state.stakeHistory.delegation;
        let date;
        for (let i = 0; i < delegation.length; i++) {
            date = moment.utc(delegation[i].time, 'YYYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            this.setDelegationDayByDate(date, delegation[i]);
        }
    }

    async getRewardsFromAddress() {

        this.setState({
            loadAnimated: true
        });

        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };


        let stakeAddress = this.state.stakeAddress;
        if (stakeAddress !== '' && stakeAddress.indexOf("stake") === 0) {

            await fetch(POSTGRES_MAINNET+'/stake_address?view=eq.' + this.state.stakeAddress, requestOptions)
                .then(response => response.text())
                .then(data =>
                    fetch(POSTGRES_MAINNET+'/reward?addr_id=eq.' + JSON.parse(data)[0].id, requestOptions)
                        .then(response => response.text())
                        .then(data => {

                            console.log('call api getRewardsFromAddress()');
                            console.log(data);
                            let stakeHistoryUpdated = this.state.stakeHistory;
                            stakeHistoryUpdated["rewards"] = JSON.parse(data);

                            this.setState({
                                rewardsHistory: data,
                                stakeHistory: stakeHistoryUpdated
                            }, () => {
                                this.getDelegationFromStakeAddress();
                                this.cleanAndSetRewardsFromAddress();
                            });
                        }).catch(e => console.log("error: " + e)));


            /*
            getRewardsByStakeAddress(stakeAddress,'https://graphql-api.mainnet.dandelion.link/').then(rewards =>{
                console.log('rewards');
                console.log(rewards);

                let stakeHistoryUpdated = this.state.stakeHistory;
                //stakeHistoryUpdated["rewards"] = JSON.parse(rewards);

                this.setState({
                    rewardsHistory: rewards
                }, () => {
                    // this.getDelegationFromStakeAddress();
                    this.cleanAndSetRewardsFromAddress();
                });
            });
            */


        }

    }

    async getDelegationFromStakeAddress() {

        let stakeAddress = this.state.stakeAddress;
        const requestOptions2 = {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"stakeAddress": stakeAddress})

        }

        fetch('https://api.dbooster.io/cardano/delegation', requestOptions2)
            .then(response => response.text())
            .then(data => {
                let stakeHistoryUpdated = this.state.stakeHistory;
                stakeHistoryUpdated["delegation"] = JSON.parse(data);
                this.setState({
                    stakeHistory: stakeHistoryUpdated
                }, () => {
                    this.setState({
                        showLoading: false
                    });
                    this.setDelegationFromStakeAddress();
                });
            })
            .catch(error => {
                console.log("error: " + error);
            });
    }

    async getPoolInfo(poolid) {

        console.log('getPoolInfo');
        console.log(poolid);
        const requestOptions = {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"poolId": poolid})
    };

        if (poolid) {
            try {
                const urlFromPoolId = await fetch('https://api.dbooster.io/cardano/getMetadataUrlFromPoolId', requestOptions);

                const poolJson = await urlFromPoolId.json();

                if (poolJson[0]["name"]) {
                    let poolJsonIdDictUpdated = this.state.poolJsonIdDict;
                    poolJsonIdDictUpdated[poolid] = poolJson;
                    this.setState({
                        poolJsonIdDict: poolJsonIdDictUpdated
                    }, () => {
                    });
                }
            } catch (e){
                console.log(e);
            }

        }
    }

    async getMetadata() {

        if (this.state.metadataKey
            && moment(this.state.metadataStartDate, 'DD-MM-YY').isValid()
            && moment(this.state.metadataEndDate, 'DD-MM-YY').isValid()
            && moment(this.state.metadataEndDate, 'DD-MM-YY').isSameOrBefore(moment(this.state.metadataEndDate, 'DD-MM-YY'))
        ) {
            let key = this.state.metadataKey;
            let start_time = moment.utc(this.state.metadataStartDate, 'DD-MM-YY').format('YYYY-MM-DD') + ''; // TODO ?? should be: .format('YYYY-MM-DD'
            let end_time = moment.utc(this.state.metadataEndDate, 'DD-MM-YY').format('YYYY-MM-DD');

            const requestOptions2 = {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"key": key, "start_time": start_time, "end_time": end_time})
            }

            fetch('https://api.dbooster.io/cardano/metadata', requestOptions2)
                .then(response => response.text())
                .then(data => {
                    let metaFiltered = JSON.parse(data).filter(meta => Date.parse(meta.block_time) > (Date.parse(start_time)));

                    this.setState({
                        metadataObjects: metaFiltered
                    }, () => {
                        // this.setMetadata();
                    })
                })
                .catch(error => {
                    console.log("error: " + error);
                });
        }
    }

    setStakeAddress(stakeAddress) {
        this.setState({
            stakeAddress: stakeAddress
        }, () => {
        })
    }

    setMetadataKey(key) {
        this.setState({
            metadataKey: key
        }, () => {

        })
    }

    setMetadataStartDate(date) {

        this.setState({
            metadataStartDate: date
        }, () => {
        })
    }

    setMetadataEndDate(date) {

        this.setState({
            metadataEndDate: date
        }, () => {
        })

    }


    setRangeSliderValue(value) {
        this.setState({
            rangeSliderValue: value
        }, () =>

            setTimeout(() => {
                this.scrollTo(moment.utc(this.state.epochs[this.state.rangeSliderValue].start_time,'YYYYY-MM-DDTHH:mm:ss').subtract(14, 'days').format('YYYY-MM-DD'))
            }, 1000));
    }

    render() {

        const address = ADDRESS;
        const addressSort = addressSlice(address,5);
        const addressSort2 = addressSlice(address,15);
        return (
            <>
                <div id="allBody">


                    <Navbar id="navBarTop" collapseOnSelect expand="lg" sticky="top" bg="dark" variant="dark">
                        <Container>
                            <Navbar.Brand href="/">
                                <span>
                                    <img src={logo} width={32}/>
                                </span>
                                {' '}
                                Cardano.date <span id="typeAppTitleSpan">demo</span>

                                {this.state.birdsAnimated ? (
                                    <BirdsFlying/>
                                ) : null}

                            </Navbar.Brand>
                            <Navbar.Brand href="">
                                {this.state.loadAnimated ? (
                                    <div className="loaderContainer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 63.5"
                                             className="sand-clock">
                                            <path
                                                d="M1.27,8.39H4.89A32.64,32.64,0,0,0,8.51,22.57C10.9,27,14.18,30.27,18,31.9a1.29,1.29,0,0,0,.51.1,1.26,1.26,0,0,0,1.16-.75A1.23,1.23,0,0,0,19,29.61C12.46,26.83,7.81,18.2,7.44,8.39H45.73A1.26,1.26,0,0,0,47,7.14V1.25A1.26,1.26,0,0,0,45.73,0H1.27A1.26,1.26,0,0,0,0,1.25V7.14A1.26,1.26,0,0,0,1.27,8.39Zm1.28-5.9h41.9V5.9H2.55Z"/>

                                            <path
                                                d="M45.73,54.83H42.11a34.43,34.43,0,0,0-3.62-14.64,21.68,21.68,0,0,0-7.31-8.46A20.9,20.9,0,0,0,37.53,25a32.65,32.65,0,0,0,4.33-13,1.27,1.27,0,1,0-2.53-.31C38.26,20.57,33.8,28,28,30.55a1.29,1.29,0,0,0-.77,1.18A1.27,1.27,0,0,0,28,32.91C34.54,35.79,39.2,44.7,39.56,54.83H7.44c.27-7.58,2.91-14.55,7.18-18.83a1.28,1.28,0,0,0,0-1.81,1.26,1.26,0,0,0-1.8,0C8.09,38.92,5.17,46.57,4.89,54.83H1.27A1.28,1.28,0,0,0,0,56.12v6.09A1.28,1.28,0,0,0,1.27,63.5H45.73A1.28,1.28,0,0,0,47,62.21V56.12A1.28,1.28,0,0,0,45.73,54.83Zm-1.28,6.1H2.55V57.41h41.9Z"/>

                                            <path className="sand-top"
                                                  d="M34.94,12.82a1.28,1.28,0,0,0-1-.5H12.51a1.27,1.27,0,0,0-1,.5,1.29,1.29,0,0,0-.24,1.12,24.16,24.16,0,0,0,4.34,9.22c2.2,2.72,4.84,4.16,7.62,4.16s5.42-1.44,7.63-4.16a24.15,24.15,0,0,0,4.33-9.22A1.32,1.32,0,0,0,34.94,12.82ZM23.21,24.72c-4.1,0-7.34-4.7-9-9.8h18C30.55,20,27.31,24.72,23.21,24.72Z"/>

                                            <path className="sand-bottom"
                                                  d="M34.94,49.82a1.31,1.31,0,0,1-1,.5H12.51a1.31,1.31,0,0,1-1.26-1.62,24.16,24.16,0,0,1,4.34-9.22c2.2-2.72,4.84-4.16,7.62-4.16s5.42,1.44,7.63,4.16a24.15,24.15,0,0,1,4.33,9.22A1.32,1.32,0,0,1,34.94,49.82ZM23.21,37.92c-4.1,0-7.34,4.7-9,9.8h18C30.55,42.62,27.31,37.92,23.21,37.92Z"/>
                                        </svg>
                                    </div>
                                ) : null}
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav>
                                    <Nav.Link id="utcIndicatorLink" href="" disabled>
                                        <div className="utcIndicatorComponent">{
                                            this.state.curTime
                                        }
                                            <div id="utc0Indicator">UTC+0</div>

                                        </div>
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>

                        </Container>
                    </Navbar>
                    <div id="mainBody">

                        <div id="mainContainer">

                            <div className="tableFixHead">
                                <div id="toolTopBar">
                                    <Button className="toolsToggler-btn" color="primary" href="#collapseExample"
                                            id="toolsToggler">
                                        <div id="navBarEpoch">


                                            <span id="epochToolBar">{this.state.rangeSliderValue}</span>


                                        </div>
                                    </Button>


                                    <UncontrolledCollapse toggler="#toolsToggler" defaultOpen={true}>
                                        <Card id="toolsSection">
                                            <CardBody>
                                                <Tabs defaultActiveKey="staking" id="uncontrolled-tab-example">
                                                    <Tab eventKey="staking" title="Staking">
                                                        <Row className="stakingContent">
                                                            <Col>


                                                                <Container>
                                                                    <h9>
                                                                        Staking timing
                                                                    </h9>
                                                                    <InputGroup>
                                                                        <InputGroupText>
                                                                            <Input addon type="checkbox"
                                                                                   aria-label="Checkbox for following text input"/>
                                                                        </InputGroupText>
                                                                        <Input
                                                                            id="stakingAddressInput"
                                                                            placeholder="stake address"
                                                                            value={this.state.stakeAddress}
                                                                            onChange={e => this.setStakeAddress(e.target.value)}
                                                                        />
                                                                        <InputGroupAddon addonType="append">
                                                                            <Button className="btnFormSend"
                                                                                    variant="outline-success"
                                                                                    onClick={this.getRewardsFromAddress}>
                                                                                Check</Button>

                                                                        </InputGroupAddon>

                                                                    </InputGroup>

                                                                    {this.state.totalRewards > 0 ? (

                                                                        <div id="totalRewardsIndicator">
                                                                            Total
                                                                            rewards({this.state.totalRewardsNum}): {this.state.totalRewards}₳
                                                                        </div>
                                                                    ) : null}

                                                                </Container>

                                                            </Col>
                                                            <Col sm={"12"} md={"6"}>

                                                                <Row>
                                                                </Row>

                                                            </Col>


                                                        </Row>
                                                    </Tab>
                                                    <Tab eventKey="metadata" title="Metadata">

                                                        <Row>
                                                            <Col sm="12" md="6 ">
                                                                <Container>
                                                                    <p>Get specific metadata by date:</p>
                                                                    <InputGroup size="md">
                                                                        <InputGroupText>
                                                                            <Input addon type="checkbox"
                                                                                   aria-label="Checkbox for following text input"/>
                                                                        </InputGroupText>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <Input type="select" name="select"
                                                                                   id="objectIdentifier">
                                                                                <option>Key</option>
                                                                            </Input>
                                                                        </InputGroupAddon>
                                                                        <Input placeholder="1968" type="text"
                                                                               value={this.state.metadataKey}
                                                                               onChange={e => this.setMetadataKey(e.target.value)}
                                                                        />
                                                                        <InputGroupAddon addonType="append">
                                                                            <Button className="btnFormSend"
                                                                                    variant="outline-success"
                                                                                    onClick={() => null/*this.getMetadata()*/ }>
                                                                                Check</Button>

                                                                        </InputGroupAddon>
                                                                    </InputGroup>

                                                                    <InputGroup size="sm">
                                                                        <InputGroupAddon addonType="prepend">Start
                                                                            date</InputGroupAddon>
                                                                        <Input
                                                                            placeholder="dd-mm-yy"
                                                                            value={this.state.metadataStartDate}
                                                                            onChange={e => this.setMetadataStartDate(e.target.value)}
                                                                        />
                                                                        <InputGroupAddon addonType="prepend">End
                                                                            date</InputGroupAddon>
                                                                        <Input
                                                                            placeholder="dd-mm-yy"
                                                                            value={this.state.metadataEndDate}
                                                                            onChange={e => this.setMetadataEndDate(e.target.value)}
                                                                        />
                                                                    </InputGroup>
                                                                </Container>
                                                            </Col>
                                                        </Row>

                                                    </Tab>
                                                    <Tab eventKey="oracles" title="Oracles" disabled>
                                                        <p>Oracles</p>
                                                    </Tab>
                                                    <Tab eventKey="tokens" title="Tokens" disabled>
                                                        <p>Native tokens</p>
                                                    </Tab>

                                                </Tabs>


                                            </CardBody>
                                        </Card>
                                    </UncontrolledCollapse>
                                </div>
                                <table>
                                    <thead>
                                    <tr id="table-tools">
                                        <th>


                                        </th>
                                    </tr>
                                    <tr>
                                        <div id="sliderRow" className="selecteurPrix">
                                            <div className="range-slider">
                                                <input id="epochsSlider"
                                                       className="input-range"
                                                       type="range"
                                                       min="0"
                                                       max={this.state.epochs.length - 1}
                                                       defaultValue={this.state.currentEpoch}
                                                       onChange={e => this.setRangeSliderValue(e.target.value)}
                                                       value={this.state.rangeSliderValue}

                                                />
                                            </div>
                                        </div>

                                    </tr>
                                    <tr>
                                        <div id="pillsBar">
                                            <div className="inLineBlock">
                                                <input id="erasModeCheckbox" type="checkbox"
                                                       onChange={e => this.handleErasCheckBox(e.target.checked)}/>
                                            </div>
                                            <div className="inLineBlock">
                                                <a href="javascript:void(0)"><Badge className="eventPill"
                                                                                    color="secondary" pill
                                                                                    onClick={() => this.scrollTo('2017-09-23')}>Byron</Badge></a>
                                                <a href="javascript:void(0)"><Badge className="eventPill"
                                                                                    color="danger" pill
                                                                                    onClick={() => this.pillsScroll('Shelley')}>Shelley</Badge></a>
                                                <a href="javascript:void(0)"><Badge className="eventPill"
                                                                                    color="success" pill
                                                                                    onClick={() => this.pillsScroll('Allegra')}>Allegra</Badge></a>
                                                <a href="javascript:void(0)"><Badge className="eventPill"
                                                                                    color="warning" pill
                                                                                    onClick={() => this.pillsScroll('Mary')}>Mary</Badge></a>
                                                <a href="javascript:void(0)"><Badge className="eventPill purple"
                                                                                    pill
                                                                                    onClick={() => this.pillsScroll('Alonzo')}>Alonzo</Badge></a>
                                                <a href="javascript:void(0)"><Badge className="eventPill"
                                                                                    id="todayPill" color="info" pill
                                                                                    onClick={() => this.scrollTo(moment.utc().subtract(1, 'week').format('YYYY-MM-DD'))}>Today</Badge></a>
                                            </div>
                                        </div>
                                    </tr>
                                    <tr className="daysHeader">
                                        <th>Sun</th>
                                        <th>Mon</th>
                                        <th>Tue</th>
                                        <th>Wed</th>
                                        <th>Thu</th>
                                        <th>Fri</th>
                                        <th>Sat</th>
                                    </tr>


                                    </thead>
                                    <tbody id="tableBody" style={{}}>


                                    {this.state.dayObjectList.map((week, i) => (

                                        <div>

                                            {this.containsFirstDayMonth(week) ? (
                                                <tr className="tr-date-span">
                                                    {this.containsFirstDayMonth(week)}
                                                </tr>
                                            ) : null}

                                            <tr key={i} className="rowCalendar">
                                                {
                                                    // Pasar a una funcion, hacer una fila finita cada vez que entre un mes nuevo y mostrar el año cuando hover
                                                    Object.keys(week).map((key, index) => (
                                                        week[key]
                                                    ))
                                                }
                                            </tr>
                                        </div>
                                    ))}


                                    </tbody>
                                    <tfoot id="footerTable">
                                    <tr>

                                        <th>
                                            <div id="dayDetails"
                                                 style={
                                                     this.state.displayFooter ? (
                                                         {display: "block"}
                                                     ) : {display: "none"}
                                                 }>

                                                <Row className={"dayDetailsBar"}>

                                                    <Col>
                                                        <div className="barElementBottom barElementBottomTittle">
                                                            <div className="barElementBottomTittle"
                                                                 style={{color: 'white'}}>
                                                                {moment.utc(this.state.displayDay.date, 'YYYY-MM-DD').format('DD dddd MMM YY')}

                                                            </div>
                                                        </div>
                                                        <div className="barElementBottom closeButtonDetailsDiv"
                                                             onClick={this.turnOffDayDetails}>
                                                            <Button id="closeDetailsButton">
                                                                <FontAwesomeIcon icon={faWindowClose}/>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div
                                                    className={this.state.displayDay.bcolor}
                                                >


                                                    <div id="epochsIndicatorsDetailsWindow">
                                                        {this.state.displayDay.epochs.length === 1 ? (
                                                            <div className="epochDayFullDetailsWindow"
                                                                 style={{width: '100%'}}>

                                                                <div className="displayInBlock">
                                                                    <div className="inLineBlock">
                                                                        e.{this.state.displayDay.epochs[0]}
                                                                    </div>
                                                                </div>

                                                                {this.state.displayDay.parameters[0] ? (

                                                                    <div
                                                                        className="inLineBlock epochParamsDetailsWindow">
                                                                        d: {((1 - this.state.displayDay.parameters[0].decentralisation) * 100).toFixed(2)}%
                                                                        k: {this.state.displayDay.parameters[0].optimal_pool_count}
                                                                        <a href="javascript:void(0)" onClick={() => {
                                                                            navigator.clipboard.writeText(this.state.displayDay.parameters[0].nonce.substring(2))
                                                                        }}> nonce <FontAwesomeIcon icon={faCopy}/></a>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : null}

                                                        {this.state.displayDay.epochs.length === 2 ? (
                                                            <div>
                                                                <div className="epochDayLeft"
                                                                     style={{width: '60%'}}>

                                                                    <div className="displayInBlock">
                                                                        <div className="inLineBlock">
                                                                            e.{this.state.displayDay.epochs[0]}

                                                                        </div>
                                                                        <div
                                                                            className="inLineBlock epochTimeRight-DetailsWindow">
                                                                            {moment.utc(this.state.epochs[this.state.displayDay.epochs[0]].end_time, 'YYYYY-MM-DDTHH:mm:ss').utc().format('hh:mm:ss')}
                                                                        </div>
                                                                    </div>

                                                                    {this.state.displayDay.parameters.length === 2 ? (

                                                                        <div
                                                                            className="inLineBlock epochParamsDetailsWindow">
                                                                            d: {((1 - this.state.displayDay.parameters[0].decentralisation) * 100).toFixed(2)}%
                                                                            k: {this.state.displayDay.parameters[0].optimal_pool_count}
                                                                            <a href="javascript:void(0)"
                                                                               onClick={() => {
                                                                                   navigator.clipboard.writeText(this.state.displayDay.parameters[0].nonce.substring(2))
                                                                               }}> nonce <FontAwesomeIcon
                                                                                icon={faCopy}/></a>
                                                                        </div>
                                                                    ) : null}

                                                                </div>
                                                                <div className="epochDayRight"
                                                                     style={{width: '40%'}}>

                                                                    <div className="displayInBlock">
                                                                        <div className="inLineBlock">
                                                                            e.{this.state.displayDay.epochs[1]}
                                                                        </div>
                                                                    </div>

                                                                    {this.state.displayDay.parameters.length === 1 ? (

                                                                        <div
                                                                            className="inLineBlock epochParamsDetailsWindow">
                                                                            d: {((1 - this.state.displayDay.parameters[0].decentralisation) * 100).toFixed(2)}%
                                                                            k: {this.state.displayDay.parameters[0].optimal_pool_count}
                                                                            <a href="javascript:void(0)"
                                                                               onClick={() => {
                                                                                   navigator.clipboard.writeText(this.state.displayDay.parameters[0].nonce.substring(2))
                                                                               }}> nonce <FontAwesomeIcon
                                                                                icon={faCopy}/></a>
                                                                        </div>
                                                                    ) : null}
                                                                    {this.state.displayDay.parameters.length === 2 ? (

                                                                        <div
                                                                            className="inLineBlock epochParamsDetailsWindow">
                                                                            d: {((1 - this.state.displayDay.parameters[1].decentralisation) * 100).toFixed(2)}%
                                                                            k: {this.state.displayDay.parameters[1].optimal_pool_count}
                                                                            <a href="javascript:void(0)"
                                                                               onClick={() => {
                                                                                   navigator.clipboard.writeText(this.state.displayDay.parameters[1].nonce.substring(2))
                                                                               }}> nonce <FontAwesomeIcon
                                                                                icon={faCopy}/></a>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                        ) : null}


                                                    </div>
                                                </div>

                                                <Row className="verticalScroll">

                                                    <Col id="rewardsCol" className="thinCol">

                                                        <div className="detailsWindowSection">

                                                            <div>
                                                                {this.state.displayDay.rewards['amount'] ? (
                                                                    <Col>
                                                                        <h4 id="rewardsTitleDetailWindow">
                                                                            Rewards
                                                                            <img id=""
                                                                                 className="adabag-icon"
                                                                                 src={require("assets/img/adaBag-iconmin2.png")}
                                                                                 alt=""/>
                                                                        </h4>
                                                                        <p className="rewardsTextInfo">Received: {this.state.displayDay.rewards['amount'] / 1000000} ₳
                                                                            = {((this.state.displayDay.rewards['amount'] / 1000000) * this.state.displayDay.dayCoinInfo.market_data.current_price.usd).toFixed(2)}$</p>
                                                                        <p className="rewardsTextInfo">
                                                                            { /*From produced blocks in epoch: */}
                                                                            {this.state.displayDay.rewards['epoch_no']}</p>

                                                                        {this.state.poolJsonIdDict[this.state.displayDay.rewards['pool_id']]
                                                                        && this.state.poolJsonIdDict[this.state.displayDay.rewards['pool_id']][0]
                                                                        && this.state.poolJsonIdDict[this.state.displayDay.rewards['pool_id']][0].ticker ? (
                                                                            <p className="">
                                                                                <span
                                                                                    className="rewardsTextInfo">By </span>
                                                                                <a target="_blank"
                                                                                   href={"https://ADApools.org/" + this.state.poolJsonIdDict[this.state.displayDay.rewards['pool_id']][0].ticker}>{this.state.poolJsonIdDict[this.state.displayDay.rewards['pool_id']][0].name}

                                                                                    {this.state.poolJsonIdDict[this.state.displayDay.rewards['pool_id']]
                                                                                    && this.state.poolJsonIdDict[this.state.displayDay.rewards['pool_id']][1] ? (
                                                                                        <img
                                                                                            className="detailsWindowPoolLogo"
                                                                                            alt=""
                                                                                            src={this.state.poolJsonIdDict[this.state.displayDay.rewards['pool_id']][1]["info"].url_png_icon_64x64}
                                                                                            width="30"
                                                                                            height="30"
                                                                                        />
                                                                                    ) : (
                                                                                        null
                                                                                    )}
                                                                                </a>


                                                                            </p>
                                                                        ) : (
                                                                            null
                                                                        )}
                                                                    </Col>
                                                                ) : (
                                                                    null
                                                                )}

                                                                {this.state.displayDay.delegation !== undefined && this.state.displayDay.delegation.time ? (
                                                                    <Col>
                                                                        <h4>Delegation:</h4>
                                                                        <p>
                                                                            Time. {moment.utc(this.state.displayDay.delegation.time,'YYYYY-MM-DDTHH:mm:ss').format("hh:mm:ss")}
                                                                        </p>
                                                                        <p>
                                                                            Active
                                                                            Epoch. {this.state.displayDay.delegation.active_epoch_no}
                                                                        </p>


                                                                        {this.state.poolJsonIdDict[this.state.displayDay.delegation.pool_hash_id]
                                                                        && this.state.poolJsonIdDict[this.state.displayDay.delegation.pool_hash_id][1] ? (
                                                                            <p>
                                                                                To
                                                                                <a target="_blank"
                                                                                   href={"https://ADApools.org/" + this.state.poolJsonIdDict[this.state.displayDay.delegation.pool_hash_id][0].ticker}>{this.state.poolJsonIdDict[this.state.displayDay.delegation.pool_hash_id][0].name}
                                                                                    {this.state.poolJsonIdDict[this.state.displayDay.delegation.pool_hash_id]
                                                                                    && this.state.poolJsonIdDict[this.state.displayDay.delegation.pool_hash_id][1] ? (
                                                                                        <img
                                                                                            className="detailsWindowPoolLogo"
                                                                                            alt=""
                                                                                            src={this.state.poolJsonIdDict[this.state.displayDay.delegation.pool_hash_id][1]["info"].url_png_icon_64x64}
                                                                                            width="30"
                                                                                            height="30"
                                                                                        />
                                                                                    ) : (
                                                                                        null
                                                                                    )}
                                                                                </a>
                                                                            </p>
                                                                        ) : (
                                                                            null
                                                                        )}
                                                                    </Col>
                                                                ) : (
                                                                    null
                                                                )}


                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col className="thinCol">
                                                        <div className="detailsWindowSection">
                                                            ₳ Price <span className="miniSpan">Coingecko</span>
                                                            {this.state.displayDay.dayCoinInfo
                                                            && this.state.displayDay.dayCoinInfo.market_data ? (

                                                                <div>
                                                                    <span
                                                                        className="priceMiniSpan">{(this.state.displayDay.dayCoinInfo.market_data.current_price.usd).toFixed(2)}$</span>
                                                                    <span
                                                                        className="priceMiniSpan">{(this.state.displayDay.dayCoinInfo.market_data.current_price.btc).toFixed(8)}₿</span>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className="detailsWindowSection">
                                                            <p>Metadata</p>
                                                            {this.state.displayDay.metadata
                                                            && this.state.displayDay.metadata.length > 0 ? (

                                                                <div id="metadatas">

                                                                    {this.state.displayDay.metadata.map((meta => (

                                                                        <div className="metadatasJson">
                                                                            <div
                                                                                className="metaTime">{meta.block_time}</div>
                                                                            <pre
                                                                                className="jsonContentMeta">{(JSON.stringify(meta, null, 2))}</pre>
                                                                        </div>
                                                                    )))}


                                                                </div>
                                                            ) : null}
                                                        </div>

                                                    </Col>

                                                </Row>


                                            </div>

                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <Button
                                                id="toolsToggler-btn-footer"
                                                onClick={null/*this.turnOffDayDetails*/}>

                                                <Row>
                                                    <Col>

                                                    </Col>
                                                    <Col>

                                                    </Col>
                                                </Row>
                                            </Button>

                                        </th>
                                    </tr>
                                    <Navbar id="navBarBottom" collapseOnSelect
                                            sticky="bottom">
                                        <Navbar.Brand href="#home">
                                            About
                                        </Navbar.Brand>
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                                        <Navbar.Collapse id="responsive-navbar-nav">
                                            <Nav className="mr-auto">
                                                <Nav.Link href="">
                                                    <OverlayTrigger
                                                        overlay={<Tooltip id="tooltip-disabled">Dandelion APIs +
                                                            ExpressJs + ReactJs</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                Dev with <FontAwesomeIcon icon={faAngleUp}/>
                                                            </span>
                                                    </OverlayTrigger>
                                                </Nav.Link>
                                                <Nav.Link href="">and <FontAwesomeIcon id="heartIconRed"
                                                                                       icon={faHeart}/> by <a
                                                    target="_blank"
                                                    href="https://adapools.org/pool/6b5179aee4db62de5bec35029e4c9b02145366acfec872f1594924db">Ada
                                                    Booster</a>
                                                    <img
                                                        alt=""
                                                        src={require("assets/img/dblogo64x64.png")}
                                                        width="30"
                                                        height="30"
                                                        className="d-inline-block align-top"
                                                    />{' '}
                                                </Nav.Link>
                                                {' '}
                                                <Nav.Link href="">
                                                    <p
                                                        onClick={() => copyText(address).then(() => {
                                                            Toast.info(addressSort2+' copied!')
                                                        })}
                                                    >Donate ADA: {addressSort}</p>
                                                </Nav.Link>
                                            </Nav>

                                            <Nav>
                                                <div className="toggle" style={{display: 'none'}}>
                                                    <input type="checkbox" className="toggle-checkbox" id="disabled"
                                                           value={this.state.toggleTheme}/>
                                                    <label className="toggle-label" htmlFor="disabled">
                                                        <span className="toggle-inner"></span>
                                                        <span className="toggle-switch"></span>
                                                    </label>
                                                </div>
                                            </Nav>
                                            <Nav>
                                                <Nav.Link id="telegramIconBottom" target="_blank"
                                                          href="https://t.me/AdaBoosterStakePool"><FontAwesomeIcon
                                                    icon={faTelegram}/> </Nav.Link>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Navbar>
                                    </tfoot>
                                </table>


                            </div>

                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default CardanoDate;
