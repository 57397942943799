import React from "react";

import "assets/scss/birds.scss";

class BirdsFlying extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            colors: props.colors
        };

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            colors: nextProps.colors,
        });
    }


    render() {


        return (
            <>

                <div>
                    <div className="bird-container bird-container--one">
                        <div className="bird bird--one"></div>
                    </div>
                    <div className="bird-container bird-container--two">
                        <div className="bird bird--two"></div>
                    </div>

                    <div className="bird-container bird-container--three">
                        <div className="bird bird--three"></div>
                    </div>
                </div>
            </>
        );
    }
}

export default BirdsFlying;

