import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class CustomEvent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: props.name,
            start_time: props.start_time,
            end_time: props.end_time,
            description: props.description,
            url: props.url,
            type: props.type,
            value: props.value
        };

    }

    intToString(value) {
        let ranges = [
            {divider: 1e18, suffix: 'E'},
            {divider: 1e15, suffix: 'P'},
            {divider: 1e12, suffix: 'T'},
            {divider: 1e9, suffix: 'G'},
            {divider: 1e6, suffix: 'M'},
            {divider: 1e3, suffix: 'k'}
        ];
        for (let i = 0; i < ranges.length; i++) {
            if (value >= ranges[i].divider) {
                return (value / ranges[i].divider).toString() + ranges[i].suffix;
            }
        }
        return value.toString();
    }

    render() {
        let rewardsFormat = 0;
        if (this.props.rewards > 0) {
            rewardsFormat = this.intToString((this.props.rewards / 1000000).toFixed(2));
        }

        return (
            <>
                <div className="eventRow">


                    <p>
                        {this.props.type === 'rewards' ? (
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">{this.props.rewards / 1000000}₳</Tooltip>}>
                                <span className="d-inline-block">
                                    <div style={{pointerEvents: 'none'}}>{this.props.icon}
                                        <div className={"rewardDayIndicator"}>{rewardsFormat}₳</div>
                                    </div>
                                </span>
                            </OverlayTrigger>
                        ) : (
                            null
                        )}

                    </p>


                    <p>
                        {this.props.type === 'delegation' ? (
                            <div className={"delegateEvent"}>{this.props.icon}
                                <div className="delegateSpan"> delegate</div>
                            </div>
                        ) : (
                            null
                        )}
                    </p>

                    <p>
                        {this.props.type === 'metadata' ? (
                            <div className={"metadataEvent"}>{this.props.icon}
                                <div className="delegateSpan"> delegate</div>
                            </div>
                        ) : (
                            null
                        )}
                    </p>


                </div>

            </>
        );
    }
}

export default CustomEvent;

